import React from "react";
import "./About.css";

export default function About() {
  return (
    <div>
      <p className="aboutustext">
        მოგესალმებით ჰიპერმარკეტი "BG-ყველაფერი ერთ სივრცეში" გთავაზობთ
        კომფორტულ და სასიამოვნო საყიდლებს.ყოველდღე დილის 9 საათიდან საღამოს 8
        საათამდე,ასევე აქ დაგხვდებათ უფასო WI-FI და იდეალური საშოპინგე
        გარემო.სრული პოდუქციის სანახავად და დეტალური ინფორმაციის მისაღებად
        გვეწვიეთ შემდეგ მისამართზე:დაბა წყნეთი,რუსთაველის ქუჩა #40.
      </p>
    </div>
  );
}
