import innovate1 from "./imgsinnovate/innovate1.jpg";

const innovatecardinfo = [
  {
    link: "1",
    desc: "მოწყობილობა მაგრდება წყლის ბალონზე და თითის დაჭერისას უშვებს წყალს,თავსებადია ყველა ზომის ბალონთან.",
    desc1: "",
    desc2: "",
    title: "წყლის სატუმბი",
    price: "25 ლარი",
    image: innovate1,
  },
];
export default innovatecardinfo;
